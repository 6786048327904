<template>
  <v-container v-if="!loading" id="add-company" fluid tag="section">
    <base-material-card
      color="primary"
      icon="mdi-office-building"
      :title="page_title()"
      class="px-5 py-3"
    >
      <v-tabs v-if="edit" v-model="tabs" @change="reset_alert">
        <v-tab>{{ $t("basic") }}</v-tab>
        <v-tab v-if="edit && roundsEnv === 'FALSE'">{{
          $t("briefing_permission")
        }}</v-tab>
      </v-tabs>

      <ManageCompanyBasic
        v-show="tabs == 0"
        @alert="show_alert"
        @saved="basic_saved"
        :OriginalIndex="originalIndex"
        :OriginalCategory="originalCategory"
        :StockOptions="stocks"
        :company_prop="company"
      ></ManageCompanyBasic>
      <ManagePartnerView
        v-if="edit && roundsEnv === 'FALSE'"
        v-show="tabs == 1"
        :company_prop="company"
      ></ManagePartnerView>
      <v-alert
        class="ma-2"
        v-if="alert_msg != null"
        :type="success ? 'success' : 'error'"
      >
        {{ $t(alert_msg) }}
      </v-alert>
    </base-material-card>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "ManageCompanyForm",

  components: {
    ManageCompanyBasic: () => import("./tabs/Basic"),
    ManagePartnerView: () => import("./tabs/PartnerView"),
  },

  data: () => ({
    apiService: new ApiService(),
    loading: false,
    company_id: null,
    originalIndex: 0,
    originalCategory: 0,
    edit: false,
    success: null,
    alert_msg: null,
    company: null,
    tabs: 0,
    stocks: [],
    roundsEnv: process.env.VUE_APP_ROUNDS,
    selectTabBankInfo: null,
  }),

  computed: {},

  async created() {
    this.company_id = this.$route.params.company_id;

    if (this.roundsEnv === "TRUE") {
      this.selectTabBankInfo = 1;
    } else {
      this.selectTabBankInfo = 2;
    }

    if (this.company_id) {
      await this.get_company();
    }
  },

  methods: {
    async get_company() {
      this.loading = true;

      await this.apiService
        .getRequest("company/get/".concat(this.company_id))
        .then((resp) => {
          this.company = JSON.parse(resp.message);
          this.originalIndex = this.company.PipelineIndex;
          this.originalCategory = this.company.PipelineCategory;
          this.edit = true;
        })
        .catch((error) => {
          this.edit = false;
        });
      this.loading = false;

      await this.load_company_stocks();
    },
    async load_company_stocks() {
      this.loading = true;

      await this.apiService
        .getRequest("stock/".concat(this.company_id))
        .then((resp) => {
          this.stocks = JSON.parse(resp.message);
        })
        .catch((error) => {});

      this.loading = false;
    },

    basic_saved() {
      this.get_company();
    },
    reset_alert: function () {
      this.success = null;
      this.alert_msg = null;
    },
    show_alert: function (success, msg) {
      this.success = success;
      this.alert_msg = msg;
    },
    page_title: function () {
      if (this.edit && !this.loading) {
        return this.company.Name;
      } else if (!this.edit) {
        return this.$t("add_company");
      } else {
        return "";
      }
    },
  },
};
</script>
